import { IOrganization } from './company.model';
import { TranslationKeys } from './translation-keys.model';

export enum JobsPages {  //domain names
  higher = 'higherjobs',
  ica = 'icajobs',
}

export const JOBS_PAGES: Map<string, IOrganization> = new Map([
  [
    JobsPages.higher,
    {
      id: null,
      guid: null,
      name: JobsPages.higher, // same as key
      primaryColor:   '#eb5929',
      logo: `${window.location.origin}/assets/images/higher-logo.svg`,
      possibleLanguages: Object.values(TranslationKeys),
      language: TranslationKeys.EN,
      queries: ['hideJobs'] // queries for getJobs endpoint
    }
  ],
  [
    JobsPages.ica,
    {
      id: null,
      guid: null,
      name: JobsPages.ica, // same as key
      primaryColor: '#9B0600',
      logo: `${window.location.origin}/assets/images/ica-logo.png`,
      logoLink: 'https://karriar.icagruppen.se/se/sv',
      possibleLanguages: [TranslationKeys.EN, TranslationKeys.SV],
      language: TranslationKeys.SV,
      jobListCustomContainerColor: '#FEF6FA',
      backgroundHeaderImage: `${window.location.origin}/assets/images/ica-jobs-background.jpg`,
      queries: ['publishOnIcaMaxiPlatform'], // queries for getJobs endpoint
      countries: [{ // if all countires should be included, skip this prop
        id: 1,
        code: 'SE',
        name: 'Sweden',
        possibleLanguages: [
          {
            key: 'sv',
            name: {
              en: 'Swedish',
              sv: 'Svenska'
            }
          },
          {
            key: 'en',
            name: {
              en: 'English',
              sv: 'Engelska'
            }
          }
        ]
      }]
    }
  ]
]);

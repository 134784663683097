import { QUIZ_TYPES } from 'src/app/resources/quiz-types';
import { Question } from './models/quiz.model';
import { PersonalityTestContent } from './models/personality-test.model';

export function readQueryParameters(paramName: string): string {
  const url = decodeURIComponent(window.location.href);
  const { queryParams } = checkForQueryParams(url);

  return queryParams[paramName] || '';
}

export function checkForQueryParams(url: string): { urlWithoutParams: string, queryParams: any } {
  const questionMarkIndex = url.indexOf('?');
  const urlLength = url.length;
  let urlWithoutParams = url;
  const queryParams = {};

  const numberOfAmpersand = (url.match(/&/g) || []).length;

  if (questionMarkIndex >= 0) {
    urlWithoutParams = url.slice(0, questionMarkIndex);
    let params = url.slice(questionMarkIndex + 1, urlLength);
    for (let i = 0; i < numberOfAmpersand + 1; i++) {
      const ampersandIndex = params.indexOf('&');
      const endIndex = ampersandIndex >= 0 ? ampersandIndex : undefined;
      const queryUrl = params.slice(0, endIndex);
      const query = queryUrl.split('=');
      const paramName = query[0];
      queryParams[paramName] = query[1];
      if (endIndex) {
        params = params.slice(ampersandIndex + 1);
      }
    }
  }

  return { urlWithoutParams, queryParams };
}

export function formatPersonalityTestQuestions(questionsResponse: PersonalityTestContent): Question[] {
  const questionGroups = Object.keys(questionsResponse);
  const personalityTest: Question[] = [];

  questionGroups.forEach((groupUUID: string) => {
    questionsResponse[groupUUID].forEach(question => {
      personalityTest.push({
        id: question.id,
        uuid: groupUUID,
        text: question.text
      });
    });
  });
  return personalityTest;
}

export function setQuizTypeQueryParam(quizType: QUIZ_TYPES): 'bq' | 'pt' | 'kq' {

  if (quizType === QUIZ_TYPES.BUSINESS_QUESTIONS) {
    return 'bq';
  } else if (quizType === QUIZ_TYPES.PERSONALITY_TEST) {
    return 'pt';
  } else if (quizType === QUIZ_TYPES.KNOCKOUT_QUESTIONS) {
    return 'kq';
  }

}

export function convertStringDateToJSDate(date: string): Date {
  const newDate = date.split('.');
  return new Date(+newDate[2], +newDate[1] - 1, +newDate[0]);
}

export function randomIntFromInterval(min, max): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function resetStorage(): void {
  sessionStorage.removeItem('jobId');
  sessionStorage.removeItem('companyId');
  sessionStorage.removeItem('companyGuid');
  sessionStorage.removeItem('companyDomain');
  sessionStorage.removeItem('jobGuid');
  sessionStorage.removeItem('jobCompanyLogo');
  sessionStorage.removeItem('url');
  sessionStorage.removeItem('email');
  sessionStorage.removeItem('jobTitle');
}
